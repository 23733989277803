import React, {useEffect, useState, useContext} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./home.css";
import Navbar from "../../components/navbar/Navbar";
import SearchBar from "../../components/searchBar/SearchBar";
import Dashboard from "../../components/dashboard/Dashboard";
import UserContext from "../../contexts/UserContext";

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(()=>{

        if(!localStorage.getItem('token')){
            navigate('/');
        }

        console.log(user)
    }, []);

    return(
        <div className="home">

            {!isClosedMenu && 
                <Navbar/>  
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar userDetails={user} isHiddenSearch={isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground">
                    <Dashboard/>
                </div>
                
            </div>          

        </div>
    );
}

export default Home;