import React from "react";
import './createPromotion.css';
import { useState } from "react";

import SearchBar from "../../components/searchBar/SearchBar";
import Navbar from "../../components/navbar/Navbar";
import { Alert} from '@mui/material'

const CreatePromotion = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);
    const [alertMessage, setAlertMessage] = useState({
        alert:'info',
        message:'Information',
    });


    return(
        <div className="modifyProfile">
            
            {!isClosedMenu && 
                <Navbar/> 
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground"> 

                    <div className="student-form-title profile-title">
                        <span >Create Promotion</span>
                    </div>  

                    <div className="alert-container">
                        <Alert className="alert-message auth-alert" severity={alertMessage.alert}>{alertMessage.message}</Alert>
                    </div>

                    <div className="promotion-data">
                    
                        <form>
                            <div className="promotion-input-container">
                                <label>Level</label>
                                <input id="level" type='number' min={1} required/>
                            </div> 
                            <div className="promotion-input-container">
                                <label>Denomination letter</label>
                                <input id="level" type='text' maxLength='1'/>
                            </div>
                            <div className="promotion-input-container">
                                <label>Option</label>
                                <select required>
                                    <option>Select &mdash;</option>
                                    <option>Software Engineering</option>
                                    <option>Business Management</option>
                                    <option>Social sciences</option>
                                </select>
                            </div>
                            <div className="promotion-input-container">
                                <label>Head Teacher</label>
                                <select>
                                    <option>Select &mdash;</option>
                                    <option>Baeni M</option>
                                    <option>Akhim</option>
                                    <option>Abednego W</option>
                                </select>
                            </div>
                            
                            <input type='submit' value='CREATE'/>
                        </form>
                    </div>    
                                
                    
                </div>
                
            </div>          

        </div>
    );
};


export default CreatePromotion;