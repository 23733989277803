import React from "react";
import './list.css';
import { useState } from "react";
import SearchBar from "../../components/searchBar/SearchBar";
import Navbar from "../../components/navbar/Navbar";
import ViewTable from "../../components/tables/ViewTable";
import { useNavigate } from "react-router-dom";



const TeachersList = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);
    const navigate = useNavigate();

    return(
        <div className="home">
            
            {!isClosedMenu && 
                <Navbar/> 
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground"> 

                    <div className="student-form-title">
                        <span>Teacher List</span>
                        {/* <span className="add-button" onClick={()=>navigate('/add-teacher')}><FontAwesomeIcon icon={faPlus}/></span> */}
                        
                    </div> 
                    <div className="student-table">
                        <ViewTable url="teachers"/>
                    </div>
                    
                </div>
                
            </div>          

        </div>
    );
};


export default TeachersList;