import './searchBar.css';
import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/ubuntu.jpg';
import UserContext from '../../contexts/UserContext';
import Modal from 'react-modal';

const SearchBar = (props) =>{
    const {user, setUser} = useContext(UserContext);

    const {isClosedMenu, setIsClosedMenu} = props
    const [showProfileOptions, setShowProfileOptions] = useState(false);
    const navigate = useNavigate()

    // const name = `${props.userDetails.name.split(' ')[0]} ${props.userDetails.name.split(' ')[1].split('')[0]}.`;
    
    // MODAL
    const customStyles= {
        overlay:{
            zIndex:'5',
        },
        content:{
            top:'50%',
            left:'50%',
            right:'auto',
            bottom:'auto',
            marginRight:'-50%',
            transform:'translate(-50%, -50%)',
            height:'13rem',
            width:'20rem',
            backgroundColor:'#9EACC1',
        },
    };
    const [modalIsOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false)
    
    const handleLogout = ()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    }

    useEffect(()=>{
        // console.log('SEARCH PROP', props.userDetails)
    })


    return (
        <div className='search-bar'>
            <div className='toggle-navigation'>
                <FontAwesomeIcon className='toggle-button' icon={faBars} onClick={() => setIsClosedMenu(!isClosedMenu)}/>
            </div>

            <div className='search-block'>
            
                {!props.isHiddenSearch &&
                    <input type="search" placeholder='Search'/>
                }
            </div>

            <div className='profile-block'>
                <span>{props.userDetails.name}</span>
                <div onClick={() => setShowProfileOptions(!showProfileOptions)} className='profile-icon'>
                    <img src={logo}/>
                </div>

                {showProfileOptions &&
                    <div className='profile-options'>
                        <div className='profile-owner'>
                            <div className='profile-image'><img src={logo}/></div>
                            <div className='profile-short-info'>
                                <span className='profileName'>{props.userDetails.name}</span>
                                <span className='profileRole'>{props.userDetails.account_type}</span>                            
                            </div>
                        </div>

                        <div className='profile-menu'>
                            <span onClick={()=>navigate('/modify_profile')}>Modify Profile</span>
                            <span>Settings</span>
                            <span onClick={()=>openModal()}>Logout</span>
                        </div>
                        
                    </div>
                }
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}  
            >
                <div className="delete-modal">
                    <span className="modal-title">Are you sure you want to logout?</span>
                    <div className="modal-controls">
                        <button className="cancel" onClick={closeModal}>CANCEL</button>
                        <button onClick={handleLogout} className="delete">LOGOUT</button>
                    </div>
                </div>

            </Modal>

        </div>
    );
};

export default SearchBar;