import React from 'react';
import './stats.css';
import Chart from "react-apexcharts";
import Table from '../tables/Table';

const Stats = () =>{

    const options = {
        chart:{id:'bar-chart'},
        grid:{
            show:false,      
        },
        height:"80%",        
        xaxis:{
            show:false,
            labels:{show:false},
            axisBorder:{show:false},
            axisTicks:{show:false},
            categories:["Jan", "Feb", "Mar", "Apr", "May", "Jun"]
        },
        plotOptions:{
            bar:{horizontal:true}
        },
        colors:["#9EACC1", "#DABAC3", "#C4D5D6","#EFDDE6",  "#DABAC3", "#EFDDE6"]
    }

    const series = [
        {
            name:'Tempera',
            data: [10, 20, 50, 200, 40, 50]
        },
        {
            name:'Tempera',
            data: [5, 25, 30, 100, 48, 5]
        }
    ]

    const pieOptions = { 
        chart:{
            type:'donut',
            toolbar:{
                show:true,
                offsetY:0,
                offsetX:"100%",
                tools:{download:true}
            }
        },
        labels : ["First", "Second", "Third", "Fourth"],
        height:"80%",
        colors:["#9EACC1", "#DABAC3", "#C4D5D6","#EFDDE6"]
    }

    const pieData = [1, 3, 7, 10];

    return (
        <div className='stats'>

            <div className='stat-one'>
                <span className='stat-title'>First title</span><hr/>
                <div className='stat-chart'>

                    <Chart
                        className="bar-chart"
                        options={options}
                        series={series}
                        type='bar'
                        height="100%"
                    />


                </div>


            </div>

            <div className='stat-one'>
                <span className='stat-title'>First title</span><hr/>
                <div className='stat-chart'>

                <div className='stat-chart-left'>
                    <Chart
                            className="pie-chart"
                            options={pieOptions}
                            series={pieData}
                            type="pie"
                        />
                </div>

                <div className='stat-chart-right'>
                    <div className='stat-chart-right-up'>
                        <span>LAST ADDED TEACHERS</span>
                        <div className='last-added'>
                            <a>
                                <div className='last-added-image'></div>
                                <div className='last-added-info'>
                                    <span>Pacifique Rubasha</span>
                                    <span>IT Specialist</span>
                                </div>
                                
                            </a><hr/>
                            <a>
                                <div className='last-added-image'></div>
                                <div className='last-added-info'>
                                    <span>Pacifique Rubasha</span>
                                    <span>IT Specialist</span>
                                </div>
                                
                            </a><hr/>
                            <a>
                                <div className='last-added-image'></div>
                                <div className='last-added-info'>
                                    <span>Pacifique Rubasha</span>
                                    <span>IT Specialist</span>
                                </div>
                                
                            </a><hr/>
                            <a>
                                <div className='last-added-image'></div>
                                <div className='last-added-info'>
                                    <span>Pacifique Rubasha</span>
                                    <span>IT Specialist</span>
                                </div>                                
                            </a><hr/>
                            <a>
                                <div className='last-added-image'></div>
                                <div className='last-added-info'>
                                    <span>Pacifique Rubasha</span>
                                    <span>IT Specialist</span>
                                </div>
                                
                            </a><hr/>
                            <a>
                                <div className='last-added-image'></div>
                                <div className='last-added-info'>
                                    <span>Pacifique Rubasha</span>
                                    <span>IT Specialist</span>
                                </div>
                                
                            </a>
                        </div>

                    </div>

                    <div className='stat-chart-right-down'>
                        <Chart
                            className="pie-chart"
                            options={pieOptions}
                            series={pieData}
                            type="donut"
                        />
                        
                    </div>
                </div>
                    

                </div>


            </div>

            <div className='stat-one'>
                <span className='stat-title'>First title</span><hr/>
                <div className='stat-chart'>
                    <Table/>
                </div>

            </div>

            

        </div>
    );
};


export default Stats;