import React from "react";

export const reloadPage = ()=>{
    window.location.reload(false);
}

export const loginFields = {
    username:"",
    password:""
}


export const institutionFields = {
    name:"",
    email:"",
    username:"",
    password:"",
    postal_box:"",
    account_type:"Primary"
}

export const commonInitialData={
    firstname:"", 
    lastname:"", 
    middlename:"", 
    age:"", 
    sex:"", 
    birthdate:"", 
    email:"", 
    username:"", 
    profile:"", 
};

export const createUser = (url, data)=>new Promise((resolve, reject)=>{
    fetch(url,
        {
            method:"POST",
            headers:{
                'Content-type':"application/json"
            },
            body:JSON.stringify(data)
        }
    )
    .then(response=>response.json())
    .then(response=>{
        resolve()
    })
});

