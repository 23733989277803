import React from "react";
import MaterialTable from '@material-table/core';
import "./material_table.css";
const Table = ()=>{
    return (
        <div className="mtcontainer">
            <MaterialTable
                style={
                    {width:'100%',
                    height:'100%',
                    border:'1px solid #DABAC3',
                    overflowY:'scroll'
                    }
                }
                columns={
                    [
                        {title:'Name', field:'name'},
                        {title:'Surname', field:'surname'},
                        {title:'BirthYear', field:'birthYear'},
                        {title:'City', field:'city', lookup:{34:'Istanbul', 63:'Goma'}}
                    ]
                }

                data={
                    [
                        {name:'Pacifique', surname:'Rubasha', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Pacifique', surname:'Rubasha', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},
                        {name:'Jay', surname:'Kay', birthYear:2002, city:63},

                    ]
                }

                options={
                    {search:false,
                    toolbar:false,
                    paging:false,
                    headerStyle:{backgroundColor:'#9EACC1', fontSize:'1.2rem', fontWeight:'900', top:0, position:'sticky'}
                    }
                    
                }

            />
            
        </div>
    );
};

export default Table;