import React, { createContext, useEffect, useRef, useContext } from 'react';
import './login.css';
import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { loginFields } from '../../utils/utils';
import { authenticateInstitution } from '../../services/apis';
import { Alert} from '@mui/material';
import UserContext from '../../contexts/UserContext';

const Login = () =>{
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loginAlert, setLoginAlert] = useState({
        visible:false,
        alert:'info',
        message:'Information',
    });

    const loginFormDataFields = Object.freeze(loginFields);
    const [loginFormData, setloginFormData]= useState(loginFormDataFields);
    
    const handleLoginChange = (e)=>{
        setloginFormData({
            ...loginFormData,
            [e.target.name] : e.target.value
        })
    }

    // const {user, setUser} = useContext(UserContext);

    const handleLogin = async (e) =>{
        e.preventDefault();
        const institution = await authenticateInstitution(loginFormData.username, loginFormData.password);

        if(institution.token){
            localStorage.setItem('token', institution.token);
            // setUser(institution.institution);

            localStorage.setItem('user', JSON.stringify(institution.institution));
            navigate('/home', {state:institution.institution});         
        }

        else setLoginAlert({visible:true, alert:'error', message:institution.error});
    }


    return (
        <div className='login'>
            <div className='loginContainer'>
                <div className='left-login'>
                    <div className='login-logo'>
                        <span className='logo'>Logo</span>
                        <span className='moto'>Short moto of the application</span>
                    </div>
                    <div className='login-signup'>
                        <span className='signup-question'>Haven't you created your account yet?</span>
                        <button onClick={()=> navigate('/register')}>SIGNUP</button>
                    </div>                    

                </div>

                
                <div className='right-login'>
                                
                    <div className='loginHeader'>
                        <h1>Login</h1>
                        <span>Don't Have An Account?&nbsp;&nbsp;
                            <span className='signup-page-link' onClick={()=> navigate('/register')}>Signup</span>
                        </span>
                    </div>

                    <form name='loginForm' className='loginForm' onSubmit={handleLogin}>
                        
                            {loginAlert.visible &&
                                <div className='alert'>
                                <Alert className="alert-message" severity={loginAlert.alert}>{loginAlert.message}</Alert>
                            </div>}

                            <div className='input-block'>
                                <span>Username</span>
                                <input name='username' onChange={handleLoginChange} type="text" required autoComplete='off'/>
                            </div>

                            <div className='input-block'>
                                <span>Password</span>
                                <input name='password' onChange={handleLoginChange} type={showPassword === false ? "password" : "text"} required/>
                                <div className='showPassword-block'>
                                    <input onClick={() => setShowPassword(!showPassword)} id="show-password" type="checkbox"/>
                                    <label htmlFor="show-password">{showPassword === false ? "Show Password" : "Hide Password"}</label>
                                </div>
                            </div>
                            
                            <input type="submit" value="LOGIN"/>   
                            
                        </form>
                </div>
                

            </div>            
        </div>
    );
};


export default Login;