import React from 'react';
import './error.css';

function Error(props) {
    return (
        <div className='error-page'>
            <span>404</span>
            <span>Not found</span> 
            <span>The requested page wasn't found</span>           
        </div>
    );
}

export default Error;