import React, { useEffect } from "react";
import './register_student.css';
import { useState } from "react";
import SearchBar from "../../components/searchBar/SearchBar";
import Navbar from "../../components/navbar/Navbar";
import UserForm from "../../components/userForm/UserForm";
import { reloadPage, commonInitialData, createUser } from "../../utils/utils";
import { Alert} from '@mui/material'

const RegisterStudent = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);
    const [alertMessage, setAlertMessage] = useState({
        alert:'info',
        message:'Information',
    });
    const [institution, setInstitution] = useState(JSON.parse(localStorage.getItem('user')));


    const initialData=Object.freeze({
        ...commonInitialData, 
        previousSchool:"",
    })

    const [formData, setFormData]= useState(initialData)

    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }    

    const url = "http://localhost:4000/students";

    const handleSubmit = (e)=>{
        e.preventDefault()        
        createUser(url, formData);
        reloadPage();         
    }

    useEffect(()=>{
        window.scrollTo({top:0, left:0, behavior:'smooth'});        
    }, [])

    return(
        <div className="home">
            
            {!isClosedMenu && 
                <Navbar/> 
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar userDetails={institution} isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground"> 

                    <div className="student-form-title">
                        <span>Add Student</span>
                    </div>   

                    <div className="alert-container">
                        <Alert className="alert-message" severity={alertMessage.alert}>{alertMessage.message}</Alert>
                    </div>

                    <form className="student-form" onSubmit={handleSubmit}>   
                        
                        <UserForm change={handleChange}/>

                        <div className="student-detail">
                      
                            <label for="school">Previous School</label>
                            <input name="previousSchool" id="school" type="text" onChange={handleChange} />
                        </div>

                        <div>
                            <input className="add-student" type='submit' value="ADD"/>
                        </div>
                        
                    </form>             
                    
                </div>
                
            </div>          

        </div>
    );
};

export default RegisterStudent;