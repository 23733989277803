import React, { useState } from 'react';
import './validateEmail.css';
import { Alert} from '@mui/material'

import { useNavigate, Link } from 'react-router-dom';

const ValidateEmail = () =>{

    const [codeAlert, setCodeAlert] = useState({
        visible:false,
        alert:'',
        message:''
    });



    const navigate = useNavigate()
    return (
        <div className='validateEMail'>
            <div className='validateEmailContainer'>
                <span>Validate email</span>
                <div className='alert-code-box'>
                    {codeAlert.visible &&                        
                        <Alert className="alert-code" severity={codeAlert.alert}>{codeAlert.message}</Alert>  
                    }
                </div>
                

                <div>
                    <label for='code'>Please enter the code sent to p.kishinyambwe@alustudent.com</label>
                    <div className='code-inputs'>
                        <input id='code' type='text'/>

                        {/* <input type='submit' value='VALIDATE' onClick={()=>navigate('/login')}/> */}

                        {/* <Link className='validate-link' 
                              to={{pathname:'/', state:{loginProps:true}}}>
                              VALIDATE
                        </Link> */}
                        <Link className='validate-link' 
                              to='/'
                              state={{loginProps:false}}>
                              VALIDATE
                        </Link>
                    </div>

                    <span onClick={()=>setCodeAlert({visible:true, alert:"success", message:"Code sent"})} className='not_received'>Code not received? <span>Resend</span></span>
                </div>

            </div>
        </div>
    );
};


export default ValidateEmail;