import { API_URL } from "../utils/url";

export async function registerInstitution(name,email,username,password,postal_box,account_type){

    const url = `${API_URL}insertInstitution.php`;
    const form = new FormData();

    form.append('name', name);
    form.append('email', email);
    form.append('username', username);
    form.append('password', password);
    form.append('postal_box', postal_box);
    form.append('account_type', account_type);

    return fetch(
                url,
                {
                    method:'POST',
                    body:form
                }
            )
            .then((response)=>response.json());

}

export async function sendCodeToInstitution(email){

    const url = `${API_URL}sendCodeToEmail.php`;
    const formData = new FormData();
    formData.append('email', email);

    return fetch(
                url,
                {
                    method:'POST',
                    body:formData
                }
            )
            .then((response)=>response.json());

}

export async function authenticateInstitution(username, password){
    const url = `${API_URL}authenticateInstitution.php`;
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return fetch(
        url,
        {
            method:'POST',
            body:formData
        }
    )
    .then((response)=>response.json());
}

export async function updateInstitution(id, name, email, username, postal_box, account_type){
    const url = `${API_URL}updateInstitution.php`;

    const formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('username', username);
    formData.append('postal_box', postal_box);
    formData.append('account_type', account_type);

    return fetch(
        url,
        {
            method:'POST',
            body:formData
        }
    )
    .then((response)=>response.json());

}

export async function getInstitutionById(id){
    const url = `${API_URL}getInstitutionById.php`;

    const formData = new FormData();
    formData.append('id', id);    

    return fetch(
        url,
        {
            method:'POST',
            body:formData
        }
    )
    .then((response)=>response.json());

}