import React, { useEffect, useState } from "react";
import MaterialTable from '@material-table/core';
import {ExportCsv} from '@material-table/exporters';
import "./material_table.css";

// WILL ADD PROPS TO COLLECT SPECIFIC DATA FOR EACH TABLE USING THIS COMPONENT
const ViewTable = (props)=>{
    
    // TABLE DATA
    const commonColumns=[
        {title:'Firstname', field:'firstname', validate:rowData=>rowData.firstname===undefined || rowData.firstname===""?"Required":true},
        {title:'Last Name', field:'lastname', validate:rowData=>rowData.lastname===undefined || rowData.lastname===""?"Required":true},
        {title:'Age', field:'age', validate:rowData=>rowData.age===undefined || rowData.age===""?"Required":true},
        {title:'Sex', field:'sex', validate:rowData=>rowData.sex===undefined || rowData.sex===""?"Required":true},
        {title:'Birth Date', field:'birthdate', validate:rowData=>rowData.birthdate===undefined || rowData.birthdate===""?"Required":true},
        {title:'Email', field:'email', validate:rowData=>rowData.email===undefined || rowData.email===""?"Required":true},
        {title:'Username', field:'username', validate:rowData=>rowData.username===undefined || rowData.username===""?"Required":true},
        {title:'Profile', field:'profile'},
    ]
    const studentColumns=[
        ...commonColumns,
        {title:'Previous School', field:'previousSchool'},
    ]

    const teachersColumns=[
        ...commonColumns,
        {title:'Primary course', field:'primaryCourse', validate:rowData=>rowData.primaryCourse===undefined || rowData.primaryCourse===""?"Required":true},
    ]

    const authoritiesColumns=[
        ...commonColumns,
        {title:'Title', field:'title', validate:rowData=>rowData.title===undefined || rowData.title===""?"Required":true},
    ]

    const student_url = "http://localhost:4000/students";
    const teacher_url = "http://localhost:4000/teachers";
    const authorities_url = "http://localhost:4000/authorities";
    
    const [data, setData] = useState([]) 

    const getUrl = ()=>{
        if(props.url==="students") return student_url
        else if(props.url==="teachers") return teacher_url
        else return authorities_url
    }

    const getColumns = ()=>{
        if(props.url==="students") return studentColumns
        else if(props.url==="teachers") return teachersColumns
        else return authoritiesColumns
    }

    const getDataName = ()=>{
        if(props.url==="students") return 'students_list'
        else if(props.url==="teachers") return 'teachers_list'
        else return 'authorities_list'
    }

    const getData = ()=>{
        fetch(getUrl())
        .then(response=>response.json())
        .then(response=>setData(response))
    }

    useEffect(()=>{
        getData()
    }, [])

    return (
        <div className="mtcontainer">
            <MaterialTable
                style={
                    {width:'100%',
                    height:'100%',
                    border:'1px solid #DABAC3'
                    }
                }
                columns={getColumns()}
                data={data}
                editable={
                    {
                        onRowAdd:(newData)=>new Promise((resolve, reject)=>{
                            //API CALL
                            fetch(getUrl(),
                                {
                                    method:"POST",
                                    headers:{
                                        'Content-type':"application/json"
                                    },
                                    body:JSON.stringify(newData)
                                }
                            )
                            .then(response=>response.json())
                            .then(response=>{
                                getData()
                                resolve()
                            })
                        }),

                        onRowUpdate:(newData, oldData)=>new Promise((resolve, reject)=>{
                            //API CALL
                            fetch(getUrl()+"/"+oldData.id,
                                {
                                    method:"PUT",
                                    headers:{
                                        'Content-type':"application/json"
                                    },
                                    body:JSON.stringify(newData)
                                }
                            )
                            .then(response=>response.json())
                            .then(response=>{
                                getData()
                                resolve()
                            })
                        }),

                        onRowDelete:(oldData)=>new Promise((resolve, reject)=>{
                            //API CALL
                            fetch(getUrl()+"/"+oldData.id,
                                {
                                    method:"DELETE",
                                    headers:{
                                        'Content-type':"application/json"
                                    }
                                },
                            )
                            .then(response=>response.json())
                            .then(response=>{
                                getData()
                                resolve()
                            })
                        })


                    }
                }                

                options={
                    {search:true,
                    showTitle:false,
                    toolbar:true,
                    paging:true,
                    headerStyle:{backgroundColor:'#9EACC1', fontSize:'1.2rem', fontWeight:'900'},  
                    columnsButton:true,
                    exportMenu:[
                        {
                            label:'Export CSV',
                            exportFunc: (columns, data) =>ExportCsv(columns, data, getDataName())
                        },
                    ],
                    actionsColumnIndex:-1,
                    addRowPosition:"first"                                
                   
                    }
                    
                }


            />
            
        </div>
    );
};

export default ViewTable;

