import React, { useRef } from 'react';
import './signup.css';
import { useState } from 'react';
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom';

import { institutionFields } from '../../utils/utils';
import { registerInstitution, sendCodeToInstitution } from '../../services/apis';
import { Alert} from '@mui/material'


const Signup = () =>{
    const navigate = useNavigate();
    const [signUpAlert, setSignUpAlert] = useState({
        alert:'info',
        message:'Information',
    });

    
    const institutionData = Object.freeze(institutionFields)
    const [signUpformData, setSignUpFormData]= useState(institutionData)
    
    const handleSignUpChange = (e)=>{
        setSignUpFormData({
            ...signUpformData,
            [e.target.name] : e.target.value
        });

        setSignUpAlert({alert:'info', message:'Information'});        

    }

    const alertReference = useRef(null);
    const scroll = ()=>alertReference.current.scrollIntoView();

    const handleSignUp = async (e) =>{
        e.preventDefault();

        const institution = await registerInstitution(signUpformData.name, signUpformData.email, 
            signUpformData.username, signUpformData.password,
            signUpformData.postal_box, signUpformData.account_type);

        if(institution.institution){

            setSignUpAlert({alert:'success', message:'Institution created successfully'});        
            e.target.reset();
            scroll();

            const sendEmail = await sendCodeToInstitution(institution.institution.email);
            if(sendEmail.result){
                // navigate('/validate_code')
            }


        }else{
            setSignUpAlert({alert:'error', message:institution.error});
            scroll();
        }

    }
    
    return (
        <div className='login'>
            <div className='loginContainer'>
                <div className='left-login'>
                    <div className='login-logo'>
                        <span className='logo'>Logo</span>
                        <span className='moto'>Short moto of the application</span>
                    </div>
                    <div className='user-signup'>
                        <button >SIGN UP AS USER</button>                        
                    </div>       
                    <div className='have-account'>
                        <span>Have an account?</span>
                        <button onClick={()=>navigate('/')}>LOGIN</button>
                    </div>             

                </div>

                <div className='right-login'>
                                
                    <div className='signupHeader'>
                        <h1>Signup</h1>
                        <span>Already Have An Account?&nbsp;&nbsp;<span onClick={() => navigate('/')} className='login-page-link'>Login</span></span>
                    </div>                       

                    <form name='signupForm' className='signupForm' onSubmit={handleSignUp}>
                        <div className='alert' ref={alertReference}>
                            <Alert className="alert-message" severity={signUpAlert.alert}>{signUpAlert.message}</Alert>
                        </div>

                        <div className='input-block'>
                            <span>Name</span>
                            <input onChange={handleSignUpChange} name='name' type="text" required/>
                        </div>
                        <div className='input-block'>
                            <span>Email</span>
                            <input onChange={handleSignUpChange} name='email' type="email" required/>
                        </div>
                        <div className='input-block'>
                            <span>Username</span>
                            <input onChange={handleSignUpChange} name='username' type="text" required/>
                        </div>
                        <div className='input-block'>
                            <span>Password</span>
                            <input onChange={handleSignUpChange} name='password' type="password" required/>
                        </div>
                        <div className='input-block'>
                            <span>Postal Box</span>
                            <input onChange={handleSignUpChange} name='postal_box' type="text" required/>
                        </div>

                        <div className='input-block'>
                            <span>Account Type</span>
                            <select onChange={handleSignUpChange} name='account_type'>
                                <option>Primary</option>
                                <option>Secondary</option>
                            </select>
                        </div>

                        <input className='signupButton' type="submit" value="SIGNUP"/>                        
                        
                    </form>
                </div>
                
            </div>            
        </div>
    );
};


export default Signup;