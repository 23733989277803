import React, {useState} from "react";
import Stats from "../statistics/Stats";
import './dashboard.css';
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faPlane, faCar, faTaxi, faCalendar, faPerson, faBars, faUser } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () =>{
    const options = {
        chart:{
            id:'line-chart',
            toolbar:{show:false}
        },
        stroke:{
            width:2
        },
        grid:{
            show:false,      
        },
        xaxis:{
            show:true,
            labels:{show:false},
            axisBorder:{show:false},
            axisTicks:{show:false}
        },

        yaxis:{
            show:true,
            labels:{show:false},
            axisBorder:{show:false},
            axisTicks:{show:false}
        },         
        colors:["#C4D5D6","#EFDDE6",  "#DABAC3", "#EFDDE6"]
    }

    const series = [
        {
            name:'Tempera',
            data: [1, 20, 30, 5100, 40, 50]
        }       
        
    ];

    return (
        <div className="dashboard">
            <div className="main-dashboard">
                <div className="dash-boxes">
                    <div className="box">
                        <div className="box-left">
                            <FontAwesomeIcon className="box-icon" icon={faBed}/>
                        </div>

                        <div className="box-content">
                            <span className="box-number">1500</span>
                            <span className="box-title">Students</span>                            
                        </div>
                       
                        <div className="triangle-left"></div>
                        
                    </div>

                    <div className="box">
                    <div className="box-left">
                            <FontAwesomeIcon icon={faPerson}/>
                        </div>

                        <div className="box-content">
                            <span className="box-number">1500</span>
                            <span className="box-title">Students</span>                            
                        </div>
                        <div className="triangle-left"></div>
                    </div>

                    <div className="box">
                    <div className="box-left">
                            <FontAwesomeIcon icon={faPlane}/>
                        </div>

                        <div className="box-content">
                            <span className="box-number">1500</span>
                            <span className="box-title">Students</span>                            
                        </div>

                        <div className="triangle"></div>
                    </div>
                    
                </div>

               <Stats/>
                
            </div>
            <div className="notifications-pannel">
                <span className="circle"></span>

            </div>

        </div>
    );
};


export default Dashboard;