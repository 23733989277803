import React from "react";
import { useState } from "react";
import SearchBar from "../../components/searchBar/SearchBar";
import Navbar from "../../components/navbar/Navbar";
import UserForm from "../../components/userForm/UserForm";
import { Alert} from '@mui/material'

import './register_authority.css'

const RegisterAuthority = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);
    const [alertMessage, setAlertMessage] = useState({
        alert:'info',
        message:'Information',
    });


    return(
        <div className="home">
            
            {!isClosedMenu && 
                <Navbar/> 
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground"> 

                    <div className="student-form-title">
                        <span>Add Authority</span>
                    </div>  

                    <div className="alert-container">
                        <Alert className="alert-message" severity={alertMessage.alert}>{alertMessage.message}</Alert>
                    </div>

                    <form className="student-form">
                    
                        <UserForm/>

                        <div className="student-detail">
                            <select required>
                                <option>Select &mdash;</option>
                                <option>Headmaster</option>
                                <option>Secretary</option>
                                <option>Head Of Finances</option>
                                <option>Director</option>
                            </select>
                        </div>

                        <div>
                            <input className="add-student" type='submit' value="ADD"/>
                        </div>
                        
                    </form>             
                    
                </div>
                
            </div>          

        </div>
    );
};


export default RegisterAuthority;