import React from "react";
import './register_teacher.css';
import { useState, useEffect } from "react";
import SearchBar from "../../components/searchBar/SearchBar";
import Navbar from "../../components/navbar/Navbar";
import UserForm from "../../components/userForm/UserForm";
import { reloadPage, commonInitialData, createUser } from "../../utils/utils";

import { Alert} from '@mui/material';


const RegisterTeacher = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);

    const [alertMessage, setAlertMessage] = useState({
        alert:'info',
        message:'Information',
    });


    const initialData=Object.freeze({
        ...commonInitialData, 
        primaryCourse:"",
    })

    const [formData, setFormData]= useState(initialData)

    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }    

    const url = "http://localhost:4000/teachers";     
    
    const handleSubmit = (e)=>{
        e.preventDefault()
        createUser(url, formData);
        
        reloadPage();        
    }

    useEffect(()=>{
        window.scrollTo({top:0, left:0, behavior:'smooth'});
                
    }, [])


    return(
        <div className="home">
            
            {!isClosedMenu && 
                <Navbar/> 
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground"> 

                    <div className="student-form-title">
                        <span>Add Teacher</span>
                    </div> 
                     
                    <div className="alert-container">
                        <Alert className="alert-message" severity={alertMessage.alert}>{alertMessage.message}</Alert>
                    </div>

                    <form className="student-form" onSubmit={handleSubmit}>
                       

                        <UserForm change={handleChange}/>

                        <div className="student-detail">
                            <label for="course">Primary Teaching Course <span className="required-star">*</span></label>
                            <input name="primaryCourse" id="course" type="text" onChange={handleChange} required/>
                        </div>

                        <div>
                            <input className="add-student" type='submit' value="ADD"/>
                        </div>
                        
                    </form>             
                    
                </div>
                
            </div>          

        </div>
    );
};


export default RegisterTeacher;