import React from "react";
import './user_form.css';

const UserForm = (props)=>{
 
    return (
        <div>
            <div className="student-detail">
                <label for="firstname">Firstname <span className="required-star">*</span></label>
                <input name="firstname" id="firstname" type="text" onChange={props.change} required/>
            </div>

            <div className="student-detail">
                <label for="lastname">Lastname <span className="required-star">*</span></label>
                <input name="lastname" id="lastname" type="text" onChange={props.change} required/>
            </div>

            <div className="student-detail">
                <label for="middlename">Middle Name</label>
                <input name="middlename" id="middlename" type="text" onChange={props.change}/>
            </div>

            <div className="student-detail">
                <label for="age">Age <span className="required-star">*</span></label>
                <input name="age" id="age" type="number" min={1} onChange={props.change} required/>
            </div>

            <div className="student-detail">
                <label for="sex">Sex <span className="required-star">*</span></label>
                <select name="sex" id="sex" onChange={props.change} required>
                    <option>Select &mdash;</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Prefer Not To Say</option>
                </select>
            </div>

            <div className="student-detail">
                <label for="birthdate">Birthdate <span className="required-star">*</span></label>
                <input name="birthdate" id="birthdate" type="date" onChange={props.change} required/>
            </div>

            <div className="student-detail">
                <label for="email">Email</label>
                <input name="email" id="email" type="email" onChange={props.change} />
            </div>

            <div className="student-detail">
                <label for="username">Username <span className="required-star">*</span></label>
                <input name="username" id="username" type="text" onChange={props.change} required/>
            </div>

            <div className="student-detail">
                <label for="profile-image">Profile Picture</label>
                <input name="profile" id="profile-image" type="file" onChange={props.change}/>
            </div>
        </div>
    );
}

export default UserForm;