import React, {useState} from "react";
import './singleUserView.css';
import Navbar from "../../components/navbar/Navbar";
import SearchBar from "../../components/searchBar/SearchBar";

import logo from '../../assets/images/ubuntu.jpg'

const SingleUserView = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);


    return (
        <div className="single-user-view">

            {!isClosedMenu && 
                <Navbar/>  
            }
            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground">
                    <div className="student-form-title profile-title">
                        <span>User Details</span>
                    </div>  

                    <div className="user-details">
                        <div className="user-details-left">
                            <img className="user-profile-image" src={logo} alt="Profile"/>
                        </div>
                        <div className="user-details-right">
                            <div className="single-user-detail">
                                <span>Name</span><span>:</span><span>Kishinyambwe</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Surname</span><span>:</span><span>Rubasha</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Middlename</span><span>:</span><span>Pacifique</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Birthdate</span><span>:</span><span>10/06/2022</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Sex</span><span>:</span><span>Male</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Age</span><span>:</span><span>20</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Email</span><span>:</span><span>p@gmail.com</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Username</span><span>:</span><span>pacifique</span>
                            </div>
                            <div className="single-user-detail">
                                <span>Previous School</span><span>:</span><span>Mwanga</span>
                            </div>

                            <div className="single-user-actions">
                                <button className="update">UPDATE</button>
                                <button className="delete">DELETE</button>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                
            </div> 

        </div>
    );

}

export default SingleUserView;