import React from "react";
import { useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTaxi, faCalendar, faPerson } from '@fortawesome/free-solid-svg-icons';
import "./navbar.css";

import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {format} from "date-fns"
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext";


const Navbar = () =>{

    const {user, setUser} = useContext(UserContext);

    const [showSubmenu, setShowSubmenu] = useState({
        register:false, teachers:false, authorities:false, fees:false, grades:false, term:false,
        promotion:false, course:false, disbursement:false
    });

    const navigate = useNavigate();

    return (
        <div className="navbar">
            <div className="nav-logo">
                <FontAwesomeIcon icon={faCalendar}/>
                <span>Your Management System</span>
            </div>
            <div className="nav-menu">
                <div className="menu-item">
                    <div className="submenu-toggle" onClick={()=>navigate('/home')}>
                        <FontAwesomeIcon className="fa-icon" icon={faTaxi}/>
                        <span className="menu-name">Dashboard</span>
                    </div>                    
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({register:!showSubmenu.register})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Students</span>
                    </div>
                   
                    {showSubmenu.register &&
                        <div className="submenu">
                        <span onClick={()=>navigate('/add-student')}>Add Students</span>
                        <span onClick={()=>navigate('/view-students')}>View Students</span>
                    </div>}
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({teachers:!showSubmenu.teachers})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Teachers</span>
                    </div>
                   
                    {showSubmenu.teachers &&
                        <div className="submenu">
                        <span onClick={()=>navigate('/add-teacher')}>Add Teachers</span>
                        <span onClick={()=>navigate('/view-teachers')}>View Teachers</span>
                    </div>}
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({authorities:!showSubmenu.authorities})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Authorities</span>
                    </div>
                   
                    {showSubmenu.authorities &&
                        <div className="submenu">
                        <span onClick={()=>navigate('/add-authority')}>Add Authorities</span>
                        <span onClick={()=>navigate('/view-authorities')}>View Authorities</span>
                    </div>}
                </div>

                

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({promotion:!showSubmenu.promotion})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Promotions</span>
                    </div>
                   
                    {showSubmenu.promotion &&
                        <div className="submenu">
                        <span onClick={()=>navigate('/create_promotion')}>Create Promotion</span>
                        <span>View Promotions</span>
                    </div>}
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({course:!showSubmenu.course})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Courses</span>
                    </div>
                   
                    {showSubmenu.course &&
                        <div className="submenu">
                        <span>Create Course</span>
                        <span>View Courses</span>
                    </div>}
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({term:!showSubmenu.term})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Terms</span>
                    </div>

                    {showSubmenu.term &&
                        <div className="submenu">
                        <span>Create Term</span>
                        <span>Create Grid</span>
                        <span>Fill Grid</span>
                    </div>}
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({fees:!showSubmenu.fees})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Fees</span>
                    </div>
                   
                    {showSubmenu.fees &&
                        <div className="submenu">
                        <span>Create Pay Term</span>
                        <span>Add Paiement</span>
                        <span>Debt List</span>
                    </div>}
                </div>

                <div className="menu-item">
                    <div onClick={() => setShowSubmenu({disbursement:!showSubmenu.disbursement})}  className="submenu-toggle">
                        <FontAwesomeIcon className="fa-icon" icon={faPerson}/>
                        <span className="menu-name">Disbursements</span>
                    </div>
                   
                    {showSubmenu.disbursement &&
                        <div className="submenu">
                        <span>Create Pay Term</span>
                        <span>Add Paiement</span>
                        <span>Past Paiements</span>
                    </div>}
                </div>

            </div>
            
        </div>
    );
}

export default Navbar