import React, { useEffect, useRef } from "react";
import './modifyProfile.css';
import { useState } from "react";

import SearchBar from "../../components/searchBar/SearchBar";
import Navbar from "../../components/navbar/Navbar";
import logo from '../../assets/images/ubuntu.jpg'
import { getInstitutionById, updateInstitution } from "../../services/apis";
import { Alert } from "@mui/material";

const ModifyProfileInstitution = ()=>{
    const [isClosedMenu, setIsClosedMenu] = useState(false);
    const [isHiddenSearch, setIsHiddenSearch] = useState(false);
    const [institution, setInstitution] = useState(JSON.parse(localStorage.getItem('user')));

    const [profileAlert, setProfileAlert] = useState({
        visible:false,
        alert:'info',
        message:'Information',
    });
    
    useEffect(() => {
        let mounted = true;
        async function fillProps() {
            const institution_payload = await getInstitutionById(institution.id);
            setInstitution(institution_payload.institution);

            localStorage.setItem('user', JSON.stringify(institution_payload.institution));
            
            const institutionProfileData = {name:institution_payload.institution.name, email:institution_payload.institution.email, username:institution_payload.institution.username, postal_box:institution_payload.institution.postal_box, account_type:institution_payload.institution.account_type};
            const data = Object.freeze(institutionProfileData);
            setProfileData(data);
        }
        if (mounted) {
            fillProps();
        }
        return () => (mounted = false);
    }, []);


    const [modifyMode, setModifyMode] = useState(false);

    const inputs = Array.from(document.querySelectorAll('input:not([type=submit])'));    
    inputs.map(input=>{
        if(modifyMode){
            input.removeAttribute('disabled');
            document.querySelector('select').removeAttribute('disabled');
            
        }else{
            input.setAttribute('disabled', '');
            document.querySelector('select').setAttribute('disabled', '');

        }
    });
    
    const institutionProfileData = {name:institution.name, email:institution.email, username:institution.username, postal_box:institution.postal_box, account_type:institution.account_type};
    const data = Object.freeze(institutionProfileData);
    const [profileData, setProfileData] = useState(data)

    const handleChange = (e)=>{  
        setProfileData({
            ...profileData,
            [e.target.name]: e.target.value
        });
    }

    const alertReference = useRef(null);
    const scroll = ()=>alertReference.current.scrollIntoView();
    const handleSave = async (e)=>{
        e.preventDefault();

        console.log('YES')
        const update_institution = await updateInstitution(institution.id, profileData.name, profileData.email, profileData.username, profileData.postal_box, profileData.account_type);

        if(update_institution.institution){
            setModifyMode(false)
            setProfileAlert({
                visible:true,
                alert:'success',
                message:'Profile updated successfully!'
            });
            scroll();
        }

    }

    return(
        <div className="modifyProfile">
            
            {!isClosedMenu && 
                <Navbar/> 
            }

            <div className={isClosedMenu ? "full-screen" : "main-ground"}>
                <SearchBar userDetails={institution} isHiddenSearch={!isHiddenSearch} isClosedMenu={isClosedMenu} setIsClosedMenu={setIsClosedMenu}/>
                <div className="playground"> 

                    <div className="student-form-title profile-title" ref={alertReference}>
                        <span >Modify Profile</span>
                    </div>

                    {profileAlert.visible && 
                        <div className="alertBox">                        
                            <Alert className="alert-message" severity={profileAlert.alert}>{profileAlert.message}</Alert>                       
                        </div>
                    }

                    <div className="profile-data">
                        <div className="profile-data-header">
                            <div className="profile-image"><img src={logo} alt='Profile'/></div>
                            <div className="profile-data-name">
                                <span>{institution.name}</span>
                                <span>{institution.account_type}</span>
                            </div>
                        </div>
                        
                        <div className="profile-form">
                            <form method="POST" onSubmit={handleSave}>
                                <div className="profile-form-left">
                                    <div className="student-detail">
                                        <label for="name">Name <span className="required-star">*</span></label>
                                        <input onChange={handleChange} value={profileData.name} defaultValue={institution.name} name="name" id="firstname" type="text"  required disabled/>
                                    </div>

                                    <div className="student-detail">
                                        <label for="email">Email <span className="required-star">*</span></label>
                                        <input onChange={handleChange} value={profileData.email} defaultValue={institution.email} name="email" id="email" type="email"  required disabled/>
                                    </div>

                                    <div className="student-detail">
                                        <label for="username">Username</label>
                                        <input onChange={handleChange} value={profileData.username} defaultValue={institution.username} name="username" id="username" type="text" disabled/>
                                    </div>

                                    
                                </div>

                                <div className="profile-form-right">
                                    <div className="student-detail">
                                        <label for="postal_box">Postal Box</label>
                                        <input onChange={handleChange} value={profileData.postal_box} defaultValue={institution.postal_box} name="postal_box" id="postal_box"  type="text" disabled/>
                                    </div>      
                                    
                                    <div className="student-detail">
                                        <label for="account_type">Account type</label>
                                        <select onChange={handleChange} id="account_type" name="account_type" disabled>
                                            <option>{institution.account_type}</option>
                                            <option>{institution.account_type === "Primary" ? "Secondary" : "Primary"}</option>
                                        </select>
                                    </div>

                                    <div className="modifyButton">
                                        {modifyMode ?
                                            <input type='submit' value="SAVE"/> :
                                            <button onClick={()=>setModifyMode(true)}>MODIFY</button>
                                        }

                                    </div>
                                    
                                </div>
                            </form>

                        </div>
                    </div>    
                                
                    
                </div>
                
            </div>          

        </div>
    );
};


export default ModifyProfileInstitution;