import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Error from './pages/error/Error';
import Home from './pages/home/Home';
import AuthoritiesList from './pages/list/AuthoritiesList';
import StudentList from './pages/list/StudentsList';
import TeachersList from './pages/list/TeachersList';
import Login from './pages/login/Login';
import ModifyProfile from './pages/profile/ModifyProfile';
import ModifyProfileInstitution from './pages/profile/ModifyProfileInstitution';
import CreatePromotion from './pages/promotion/CreatePromotion';
import RegisterAuthority from './pages/register_authority/RegisterAuthority';
import RegisterStudent from './pages/register_student/RegisterStudent';
import RegisterTeacher from './pages/register_teacher/RegisterTeacher';
import Signup from './pages/signup/Signup';
import SingleUserView from './pages/single_user/SingleUserView';
import ValidateEmail from './pages/validateEmail/ValidateEmail';

function App(){
  return (
    
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Login/>}/>
        <Route exact path='/register' element={<Signup/>}/>

        <Route path='/validate_code' element={<ValidateEmail/>}/>

        <Route path='/modify_profile' element={<ModifyProfileInstitution/>}/>

        <Route path='/home' element={<Home/>}/>
        <Route path='/add-student' element={<RegisterStudent/>}/>
        <Route path='/view-students' element={<StudentList/>}/>

        <Route path='/add-teacher' element={<RegisterTeacher/>}/>
        <Route path='/view-teachers' element={<TeachersList/>}/>

        <Route path='/add-authority' element={<RegisterAuthority/>}/>
        <Route path='/view-authorities' element={<AuthoritiesList/>}/>

        <Route path='/single_user/' element={<SingleUserView/>}/>

        <Route path='/create_promotion' element={<CreatePromotion/>}/>

        <Route path='*' element={<Error/>}/>

      </Routes>
    </BrowserRouter>
  );
}


export default App;